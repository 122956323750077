// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

require("packs/filterrific-jquery");
require("packs/resources_filters");
require("packs/datepicker");
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
require("jquery");
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import frLocale from '@fullcalendar/core/locales/fr';
import { config } from '@fullcalendar/common';

config.timeGridEventCondensedHeight = 65;

const queryString = require('query-string');

var initialize_components;
initialize_components = function () {
  $(".ui.dropdown").dropdown();
  $(".ui.checkbox").checkbox();
};

function setupCalendar(days=[]) {
  var calendarEl = document.getElementById('calendar');
  var date = Date.parse(decodeURIComponent(queryString.parse(window.location.search)["date"])) || new Date();
  var calendar = new Calendar(calendarEl, {
    locale: frLocale,
    initialView: queryString.parse(window.location.search)["view"] || "timeGridWeek",
    headerToolbar: { center: 'dayGridMonth,timeGridWeek,listWeek' },
    plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
    initialDate: date,
    nowIndicator: true,
    weekNumbers: true,
    weekends: true,
    hiddenDays: days,
    slotMinTime: "07:00:00",
    slotMaxTime: "22:00:00",
    height: "auto",
    allDaySlot: false,
    eventClick: function (info) {
      console.log(info.event);
    },
    eventDidMount: function (info) {
      var durationInMinutes = 60;
      if (info.event.start != null && info.event.end != null) {
        var diffMilliseconds = Math.abs(info.event.end - info.event.start) / 1000;
        durationInMinutes = Math.floor(diffMilliseconds / 60);
      }

      if (info.view.type === 'timeGridWeek') {
        console.log(info.el);
        var timeDiv = info.el.firstChild.firstChild.firstChild;
        var titleDiv = info.el.firstChild.firstChild.lastChild;
        if (durationInMinutes <= 15) {
          titleDiv.style.lineHeight = '0.6';
        } else if (durationInMinutes > 15 && durationInMinutes <= 30) {
          titleDiv.style.lineHeight = '1.4';
        } else if (durationInMinutes > 30 && durationInMinutes <= 45) {
          timeDiv.style.lineHeight = '1.5';
          titleDiv.style.shrink = 'unset';
          titleDiv.firstChild.style.lineHeight = '1';
        }
        // We hide the time for events shorter than a half hour
        if (durationInMinutes <= 30) {
          timeDiv.style.display = 'none';
        } else {
          // For others events we display the title before the time
          info.el.firstChild.firstChild.style.cssText = 'flex-direction:column-reverse;justify-content:flex-end;';
          titleDiv.style.cssText = 'flex-grow:0;-webkit-flex-shrink:0;';
          info.el.classList.remove('fc-timegrid-event-condensed'); // Otherwise a '-' is added at the end of the time text for 1 hour events.
        }
        // Adds an info icon before title for events with descriptions or webconference links
        if (info.event.extendedProps.viewMore) {
          var viewMoreIcon = document.createElement('i');
          viewMoreIcon.classList.add('ui', 'info', 'circle', 'icon');
          viewMoreIcon.style.cssText = 'font-size:0.8em;vertical-align:top;display:inline;';
          info.el.firstElementChild.firstElementChild.lastElementChild.firstElementChild.prepend(viewMoreIcon);
        }
      }
    },
    viewDidMount: function (info) {
      var options = window.location.search.substring(1).split("&").filter(x => x != "" && !x.startsWith("view"));
      var new_url = window.location.origin + window.location.pathname + "?view=" + info.view.type;
      options.forEach(options => new_url += "&" + options);
      history.pushState({}, "", new_url);
    },
    datesSet: function (info) {
      var options = window.location.search.substring(1).split("&").filter(x => x != "" && !x.startsWith("date"));
      var new_url = window.location.origin + window.location.pathname + "?date=" + encodeURIComponent(calendar.getDate().toISOString());
      options.forEach(options => new_url += "&" + options);
      history.pushState({}, "", new_url);
    }
  });

  calendar.render();
  return calendar;
}

window.setupCalendar = setupCalendar;

$(document).on("turbolinks:load", function () {
  $("a.reply").on("click", function () {
    $(this).closest(".content").children("form.reply").slideToggle();
  });
  $(".new_modal .button").on("click", function () {
    $(".ui.modal").modal("show");
  });
  initialize_components();
});

$(document).on("ajax:complete", function () {
  initialize_components();
});

$(document).on("change", "[data-toggle-ui]", function (event) {
  if (
    (this.checked == true && this.dataset["toggleDirection"] == "right") ||
    (this.checked == false && this.dataset["toggleDirection"] == "inverse")
  ) {
    $(this.dataset["toggleUi"]).removeClass("disabled");
    $(this.dataset["toggleUi"] + " input[type='checkbox']").attr(
      "disabled",
      false
    );
  } else {
    $(this.dataset["toggleUi"]).addClass("disabled");
    $(this.dataset["toggleUi"] + " input[type='checkbox']").attr(
      "disabled",
      true
    );
  }
});

const Trix = require("trix")
require("@rails/actiontext")

Trix.config.blockAttributes.heading1.tagName = "h3";

import Sortable from 'sortablejs'

document.addEventListener('turbolinks:load', () => {
  document.addEventListener('click', (event) => {
    let element = event.target.closest('.paragraph-content')
    if (!element) return;

    element.classList.add('d-none')
    element.nextElementSibling.classList.remove('d-none')
  })

  document.addEventListener('click', (event) => {
    if (!event.target.matches('.cancel')) return;
    event.preventDefault();

    let element = event.target.closest('.paragraph-form')

    element.classList.add('d-none')
    element.previousElementSibling.classList.remove('d-none')
  })

  let element = document.getElementById('elements')
  if (element)
    Sortable.create(element, { animation: 500 })
})
import "controllers"

