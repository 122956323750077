$(document).on("turbolinks:load ajax:complete ready", function(event) {
  $(".ui.calendar.date_only .action").calendar({type: 'date'});
  $('.ui.checkbox').checkbox();
});

$(document).on("turbolinks:load ajax:complete ready", function(event) {
  $(".ui.calendar.datetime .action").calendar(
    {
      type: 'datetime',
      formatter: {
        date: function (date, settings) {
          var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

          if (month.length < 2) month = '0' + month;
          if (day.length < 2) day = '0' + day;

          return [day, month, year].join('/');
        },
        time: function (time, settings) {
          if (!time) return '';
          var hour   = ("0" + time.getHours()).slice(-2);
          var minute = ("0" + time.getMinutes()).slice(-2);
          return hour + ":" + minute;
        }
      }
    });
  $('.ui.checkbox').checkbox();
});
