$( document ).on('turbolinks:load', function() {
  $('.resources_filters').on("change", ".checkbox", function() {
        var resources_ids = Array.from($('.resources_filters').get(0).children)
                        .filter(checkbox => checkbox.classList.contains("checked"))
                        .map(checkbox => checkbox.getElementsByTagName("input")[0].id);
        var options = window.location.search.substring(1).split("&").filter(x => x != "" && !x.startsWith("resources"));
        resources_ids.forEach(id => options.push("resources[]="+id));
        var new_url = window.location.origin + window.location.pathname;
        if (options.length > 0) {
          new_url += "?";
          options.forEach(options => new_url += options + "&");
          new_url = new_url.slice(0,-1);
        }
        history.pushState({}, "", new_url);
        $.ajax({ url: new_url, method: 'GET', dataType: 'script' });
      });
});

window.onpopstate = function(event) {
    if(event && event.state) {
        location.reload();
    }
}
